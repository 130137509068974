<template>
  <div class="detail">
    <div class="top_bar">
      <div class="top_bar_icon" @click="handleRouter">
        <van-icon name="arrow-left" color="#7e7e7e" size="16" />
      </div>
      <div>商品详情</div>
    </div>
    <div class="shop_img">
      <van-swipe :autoplay="3000" loop>
        <van-swipe-item v-for="item in images" :key="item.img_id">
          <img :src="item.image_url" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="shop_text">
      <div class="text_one">
        <div><span>{{detailData.shop_price}}</span>/{{detailData.spu}}</div>
        <div>销量：{{detailData.sales}}件</div>  
      </div>
      <div class="text_two">{{detailData.goods_name}}</div>
      <div class="text_three">{{detailData.goods_remark}}</div>
    </div>
    <!-- 商品详情 -->
    <div class="shop_detail">
      <div class="line"></div>
      <div class="detail_title">商品详情</div>
      <div class="line_right"></div>
      <div class="detail_html" v-html="detailData.goods_content"></div>
      <!-- <div class="detail_html" >
              <img src="../../assets/image/details.png" alt="">
      </div>-->
    </div>
    <!-- 底部 -->
    <div class="footer">
      <!-- <div class="btn" @click="handleExchange">立即兑换</div> -->
      <div class="btn" @click="handleExchange">{{detailData.btn}}</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      details: "<p>富文本</p>",
      goods_id: "",
      detailData: {},
      userinfo: {},
      images: []
    };
  },
  methods: {
    handleRouter() {
      this.$router.go(-1);
    },
    handleExchange() {
      if(this.userinfo.user_type=='customer' || this.userinfo.user_type==''){
        // this.$notify({ type: "danger", message: "您没有通过二维码认证，请咨询您所属区域的总代理!" });
        // return;
        this.$router.push({
          path: "/buy/index",
          query: {
            id: this.goods_id
          }
        });
      }else{
        this.$router.push({
          path: "/exchange/index",
          query: {
            id: this.goods_id
          }
        });
      }
    },
    async handleuserinfo() {
      const data = await this.$api.userinfo();
      console.log(data, "data");
      if (data.data.code === 1) {
        this.userinfo = data.data.data;
      } else {
        this.$notify({ type: "danger", message: data.data.msg });
      }
    },
    async handleGetDetail() {
      const data = await this.$api.getDetail({
        goods_id: this.goods_id
      });
      if (data.data.code === 1) {
        this.detailData = data.data.data.goodsinfo;
        this.images = data.data.data.goodsimages;
      } else {
        this.$notify({ type: "danger", message: data.data.msg });
      }

      console.log(data, "data");
    }
  },
  mounted() {
    this.goods_id = this.$route.query.id;
    this.handleGetDetail();
    this.handleuserinfo()
  }
};
</script>
<style lang="scss" scoped>
.detail {
  width: 375px;
  height: auto;
  padding-bottom: 80px;
  padding-top: 40px;
  box-sizing: border-box;
  .shop_img {
    width: 375px;
    height: 364px;
    img {
      width: 375px;
      height: 364px;
    }
  }
  .shop_text {
    width: 375px;
    height: 181px;
    background: rgba(255, 255, 255, 1);
    padding: 20px 19px 15px 20px;
    box-sizing: border-box;
    .text_one {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      height: 26px;
      font-size: 15px;
      color: #666;
      line-height: 26px;
      span {
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #fe0306;
        font-size: 26px;
      }
    }
    .text_two {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(51, 51, 51, 1);
      line-height: 24px;
      margin-top: 15px;
    }
    .text_three {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(153, 153, 153, 1);
      line-height: 21px;
      margin-top: 15px;
    }
  }
  .shop_detail {
    width: 100%;
    position: relative;

    .line {
      width: 15px;
      height: 1px;
      background: rgba(215, 215, 215, 1);
      position: absolute;
      top: 29px;
      left: 129px;
    }
    .line_right {
      width: 15px;
      height: 1px;
      background: rgba(215, 215, 215, 1);
      position: absolute;
      top: 29px;
      right: 130px !important;
    }
    .detail_title {
      width: 100%;
      height: 45px;
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: rgba(102, 102, 102, 1);
      line-height: 18px;
      text-align: center;
      background: #fafafc;
      padding-top: 20px;
      box-sizing: border-box;
    }
    .detail_html {
      width: 375px;
      height: auto;
      overflow: hidden;
    }
  }
  .footer {
    width: 375px;
    height: 60px;
    background: rgba(255, 255, 255, 1);
    position: fixed;
    bottom: 0;
    padding-top: 10px;
    box-sizing: border-box;
  }
}
</style>
